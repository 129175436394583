import React from 'react';

import './styles.scss';

type MainProps = {
  children: React.ReactNode;
  className?: string;
};

const Main: React.FunctionComponent<MainProps> = ({ children, className }) => {
  return <main className={className}>{children}</main>;
};

export default React.memo(Main);
